import {
  Button,
  ChatIcon,
  // ClipboardCopiedToIcon,
  Flex,
  FlexItem,
  OpenOutsideIcon,
  Text,
} from '@fluentui/react-northstar';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dialog, AdaptiveCardDialogInfo, DialogDimension } from '@microsoft/teams-js';

import { LiveCaption } from 'FEATURES/tab/inMeeting/liveCaption/LiveCaption';
import LanguageDropdown from 'FEATURES/tab/inMeeting/languageDropdown/LanguageDropdown';
import useInMeeting from 'FEATURES/tab/inMeeting/hooks/useInMeeting';

import FullScreenLoader from 'COMPONENTS/FullScreenLoader';
import CallEnded from 'COMPONENTS/callEnded/CallEnded';

// Constants
import { USER_ROLE } from 'CONSTANTS/enum';
import {
  TEAMS_ERROR,
  LANGUAGE_PREFIX,
  TEAMS_LANGUAGE_RESOURCE,
  MESSAGE_FORMAT,
  SERVER_MESSAGE,
  ADAPTIVE_CARD_SCHEMA,
} from 'CONSTANTS/teamsConstants';
import { TAB_LOACTION } from 'CONSTANTS/apiConstants';

// Hooks
import useMessage from 'HOOKS/useMessage';
import useCaptioner from 'HOOKS/useCaptioner';

// Services
import logger from 'SERVICES/logger';
import PubSubClient from 'SERVICES/PubSubClient';

// Store
import { captionsSelector } from 'STORE/captionsSlice';
import { meetingSelector, selectMeetingSpokenLanguages } from 'STORE/meetingSlice';

// Utils
import { ICardContentObject, ISpokenLanguage } from 'UTILS/teamsInterface';

import 'FEATURES/tab/inMeeting/InMeeting.css';

// TODO: check destructor functioning
const InMeeting = () => {
  const spokenLanguages = useSelector(selectMeetingSpokenLanguages);
  const selectedSpokenLanguages = useSelector(meetingSelector.selectedSpokenLanguages);
  const userRole = useSelector(meetingSelector.currentUserRole);
  const meetingLanguage = useSelector(meetingSelector.meetingLanguages);
  const meetingDetails = useSelector(meetingSelector.meetingDetails);
  const serverMessage = useSelector(captionsSelector.serverMessageSelector);
  const isCaptionerPresent = useSelector(captionsSelector.isCaptionerPresentSelector);
  // pageLoader shows the full page loader
  // languageLoader shows loader for on the language dropdown when language is changed
  const {
    pageLoader,
    updateLanguage,
    languageLoader,
    userPrincipalName,
    userObjectId,
    setSpokenLanguage,
  } = useInMeeting();
  const { handleCaptions, handleWsMessage, handleCallStatus, handleCaptionerStatus } = useMessage();

  const {
    // copyLinkToClipboard,
    openPopup,
  } = useCaptioner(TAB_LOACTION.inMeeting);
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);

  const [wsClient, setWsClient] = useState<any>(null);

  // storing subscription id
  const [connectionSubId, setConnectionSubId] = useState<any>('');
  const [captionSubId, setCaptionSubId] = useState<any>('');
  const [messageSubId, setMessageSubId] = useState<any>('');
  const [captionerStatusSubId, setCaptionerStatusSubId] = useState<any>('');
  const [callStatusSubId, setCallStatusSubId] = useState<any>('');

  const languageUpdater = async (language: ISpokenLanguage) => {
    try {
      logger.debug('Changing language to: ', language);
      await updateLanguage(language);
      // language change message body
      const userLanguageChangeMessage = {
        userId: userPrincipalName,
        spokenLanguageCode: language.code,
        userObjectId,
      };
      // sending message over WS
      await wsClient.sendMessageToGroup(
        meetingDetails.meetingId,
        { userSpokenLanguageChanged: userLanguageChangeMessage },
        MESSAGE_FORMAT.JSON
      );
      // storing selected language in store
      setSpokenLanguage(language);
    } catch (error) {
      logger.error(TEAMS_ERROR.CHANGE_LANGUAGE, error);
    }
  };

  // socket cleanup
  const cleanup = () => {
    (async () => {
      // user disconnect message body
      const userDisconnectedMessage = {
        userId: userPrincipalName,
      };
      // sending dicosconnect message
      await wsClient?.sendMessageToGroup(
        meetingDetails?.meetingId,
        { userDisconnected: userDisconnectedMessage },
        MESSAGE_FORMAT.JSON
      );
      // unsubscribing the events
      await wsClient.unsubscribe(PubSubClient.WS_EVENT.WS_CONNECTED, connectionSubId);
      await wsClient.unsubscribe(PubSubClient.WS_EVENT.CAPTIONS, captionSubId);
      await wsClient.unsubscribe(PubSubClient.WS_EVENT.WS_MESSAGE, messageSubId);
      await wsClient.unsubscribe(PubSubClient.WS_EVENT.CALL_STATUS, callStatusSubId);
      await wsClient.unsubscribe(PubSubClient.WS_EVENT.CAPTIONER_STATUS, captionerStatusSubId);
      // websocket client cleanup
      await wsClient?.cleanup();
    })();
  };

  // creates in meeting dialogue
  const createInMeetingDialogue = async (cardContentObject: ICardContentObject) => {
    const adaptiveCardContent = {
      type: ADAPTIVE_CARD_SCHEMA.CARD_TYPE,
      body: [
        {
          type: ADAPTIVE_CARD_SCHEMA.BODY_TYPE,
          text: t(cardContentObject.cardBodyContentKey),
          wrap: true,
        },
      ],
      actions: [
        {
          type: ADAPTIVE_CARD_SCHEMA.ACTION_SUBMIT,
          title: t(cardContentObject.cardActionKey),
          style: ADAPTIVE_CARD_SCHEMA.CARD_STYLE,
        },
      ],
      $schema: ADAPTIVE_CARD_SCHEMA.CARD_SCHEMA,
      version: ADAPTIVE_CARD_SCHEMA.VERSION,
    };

    const adaptiveCardDialogInfo: AdaptiveCardDialogInfo = {
      title: t(cardContentObject.cardTitleKey) as string,
      card: JSON.stringify(adaptiveCardContent),
      size: {
        height: DialogDimension.Small,
        width: DialogDimension.Medium,
      },
    };

    dialog.adaptiveCard.open(adaptiveCardDialogInfo, () => {
      return;
    });
  };

  // creates websocket client and subscribes to its events
  useEffect(() => {
    if (!meetingDetails.meetingId) return;

    const webSocketClient = new PubSubClient();
    (async () => {
      try {
        await webSocketClient.init(meetingDetails?.pubsubClient?.url);

        // storing WS_CONNECTED event subscription id
        setConnectionSubId(
          // subscribing to WS_CONNECTED event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_CONNECTED, (eventData: any) => {
            logger.info(eventData);
            // WS_CONNECTED message body
            // sending userPrincipalName (email) and UserObjectId to captioner in order to
            // update the user Map on captioner interface
            const userConnectedMessage = {
              userId: userPrincipalName,
              userObjectId: userObjectId,
            };
            // sending message to group about the successfull connection
            webSocketClient.sendMessageToGroup(
              meetingDetails?.meetingId,
              { userConnected: userConnectedMessage },
              MESSAGE_FORMAT.JSON
            );
          })
        );
        // storing CAPTIONS event subscription id
        await setCaptionSubId(
          // subscribing to CAPTIONS event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.CAPTIONS, (eventData: any) => {
            logger.info(eventData);
            handleCaptions(eventData);
          })
        );
        // storing WS_MESSAGE event subscription id
        await setMessageSubId(
          // subscribing to WS_MESSAGE event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_MESSAGE, (eventData: any) => {
            if (eventData) {
              logger.info(eventData);
              handleWsMessage(eventData);
            }
          })
        );
        // storing CAPTIONER_STATUS event subscription id
        await setCaptionerStatusSubId(
          // subscribing to CAPTIONER_STATUS event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.CAPTIONER_STATUS, (eventData: any) => {
            logger.info(eventData);
            handleCaptionerStatus(eventData);
          })
        );
        // storing CALL_STATUS event subscription id
        await setCallStatusSubId(
          // subscribing to CALL_STATUS event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.CALL_STATUS, (eventData: any) => {
            logger.info(eventData);
            handleCallStatus(eventData);
          })
        );
        // joining group
        await webSocketClient.joinGroup(meetingDetails?.meetingId);
        // storing socket client
        setWsClient(webSocketClient);
      } catch (error) {
        logger.error('unable to create client', error);
      }
    })();
  }, [meetingDetails]);

  // function to connect to the previously selected language if any
  // when reloading the sidepanel or rejoining the meeting
  const connectToPreviousLanguage = async () => {
    try {
      await languageUpdater(selectedSpokenLanguages);
    } catch (error) {
      logger.error(TEAMS_ERROR.CHANGE_LANGUAGE, error);
    }
  };

  // checking if previously connected to meeting then reconnect to the previous language
  useEffect(() => {
    if (selectedSpokenLanguages?.code && wsClient)
      (async () => await connectToPreviousLanguage())();
  }, [spokenLanguages, wsClient]);

  // cleanup when call ends
  useEffect(() => {
    if (serverMessage === SERVER_MESSAGE.STOP_CAPTIONS) cleanup();
  }, [serverMessage]);

  // used as destructor
  useEffect(() => {
    // IIFE to call createInMeetingDialogue
    (async () => {
      const cardContentObject: ICardContentObject = {
        cardTitleKey: 'MICROPHONE_WARNING_TITLE',
        cardBodyContentKey: 'MICROPHONE_WARNING_TEXT',
        cardActionKey: 'CLOSE_BUTTON_TEXT',
      };
      await createInMeetingDialogue(cardContentObject);
    })();
    return cleanup;
  }, []);

  const openChat = async () => {
    /* Commenting code for chat navigation as we didn't receive any updates on the issue reported to microsoft yet.
       (https://github.com/OfficeDev/microsoft-teams-library-js/issues/2485) 
    */
    /* 
      This method will be convert msTeamsMeetingUrl from response object into required chat Url.
      executeDeepLink() method redirects to conversation of current meeting.
    */
    // const chatLink = meetingDetails?.msTeamsMeetingUrl.replace(
    //   TEAMS_MEETING_LINK_PARAMS.JOIN_MEETING,
    //   TEAMS_MEETING_LINK_PARAMS.CHAT
    // );
    // app.openLink(chatLink);
    const cardContentObject: ICardContentObject = {
      cardTitleKey: 'CHAT_NAVIGATION',
      cardBodyContentKey: 'CHAT_NAVIGATION_DESCRIPTION',
      cardActionKey: 'CLOSE_BUTTON_TEXT',
    };
    await createInMeetingDialogue(cardContentObject);
  };

  // if pageLoader show loader
  if (pageLoader) return <FullScreenLoader label={t('LOAD_MEETING_INFO_LOADER_MSG')} />;

  // if teams user disconnects and then captions will be stopped
  if (serverMessage === SERVER_MESSAGE.STOP_CAPTIONS) return <CallEnded />;

  // if meeting is not configured then prompt the user
  if (meetingLanguage.length < 1)
    return (
      <Flex fill>
        {userRole === USER_ROLE.ORGANIZER ? (
          <Text content={t('NO_LANGUAGE_CONFIGURED_ORGANIZER')} />
        ) : (
          <Text content={t('NO_LANGUAGE_CONFIGURED_PARTICIPANT')} />
        )}
      </Flex>
    );

  return (
    <Flex fill column gap="gap.medium" className="in-meeting-container">
      <Flex column gap="gap.smaller">
        <Flex gap="gap.small" vAlign="center" space="between">
          <Text content={t('SELECT_LANGUAGE_HEADING')} />
          <FlexItem align="end">
            <Flex
              column
              hAlign="center"
              gap="gap.smaller"
              onClick={openChat}
              className="open-chat-icon"
            >
              <ChatIcon outline size="large" className="chat-icon" />
              <Text content={t('OPEN_CHAT')} size="small" className="chat-icon-lable" />
            </Flex>
          </FlexItem>
        </Flex>
        <LanguageDropdown
          spokenLanguages={spokenLanguages}
          updateLanguage={languageUpdater}
          loading={languageLoader}
        />
        {userRole === USER_ROLE.ORGANIZER && (
          <Flex gap="gap.smaller" column>
            <Text content={t('ORGANIZER_DIRECTIONS')} />
            {/* copy to clipboard button(Full button) */}
            {/* <Button
              icon={<ClipboardCopiedToIcon />}
              iconPosition="after"
              content={t('COPY_CAPTIONER_LINK')}
              onClick={copyLinkToClipboard}
            /> */}
            {/* Open PopOut button */}
            <Button
              className="pop-out-button"
              iconPosition="after"
              onClick={openPopup}
              disabled={isCaptionerPresent}
            >
              <Flex gap="gap.medium" vAlign="center">
                <Flex hAlign="center" column>
                  <FlexItem>
                    <Text content={t('START_CAPTIONS_TEXT')} align="center" />
                  </FlexItem>
                  <FlexItem>
                    <Text
                      content={`(${t('POP_OUT_TEXT')} ${
                        process.env.REACT_APP_CAPTIONER_URL?.split('//')[1]
                      })`}
                      align="center"
                      size="small"
                      weight="bold"
                    />
                  </FlexItem>
                </Flex>
                <FlexItem>
                  <OpenOutsideIcon size="large" />
                </FlexItem>
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>
      <FlexItem flexDirection="column">
        <LiveCaption loading={languageLoader} />
      </FlexItem>
    </Flex>
  );
};

export default InMeeting;
